
<template>
  <a-sub-menu v-bind="$attrs">
    <template #title>
      <span>
        <span>{{ menuInfo.resourceName }}</span>
      </span>
    </template>
    <template v-for="item in menuInfo.children" :key="item.key">
      <template v-if="!item.children">
        <a-menu-item :key="item.key">
          <span>{{ item.resourceName }}</span>
          <a-button type="primary" ghost v-if="item.isProduction" @click="addvideo(item, menuInfo.children)">添加播放</a-button>
        </a-menu-item>
      </template>
      <template v-else>
        <sub-menu :menu-info="item" :key="item.key" />
      </template>
    </template>
  </a-sub-menu>
</template>

<script>
import bus from '../bus';

export default {
  name: 'SubMenu',
  props: {
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, content) { 
    const addvideo = (params, item) => {
      params.resourceType = item.resourceType;
      bus.emit('addVideoPlay', params);
    };
    return { addvideo };
  },
};
</script>
