<!-- 视频播放 || 直播 -->
<template>
  <div :id="configuration.id" class="video"></div>
</template>

<script>
import { onMounted, watch } from 'vue';
export default {
  props: {
    configuration: {
      type: Object
    },
    async: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    onMounted(() => {
      if (!props.async) loadCkplayer();
    });
    watch(props.configuration, () => {
      if (props.async) loadCkplayer();
    });
    const loadCkplayer = () => {
      const videoObject = {
        container: `#${props.configuration.id}`, //容器的ID或className
        variable: 'player', //播放函数名称
        autoplay: props.configuration.autoplay,
        html5m3u8: props.configuration.html5m3u8 || false,
        // loaded: 'loadedHandler',
        live: props.configuration.live,
        video: props.configuration.url
        // loop: props.configuration.html5m3u8 || false,
      };
      new ckplayer(videoObject);
      // if (props.configuration.load) {
      //   window.player = new ckplayer(videoObject);
      // } else {
        
      // }
      // window.loadedHandler = function() {
      //   window.player.addListener('screenshot', screenshotHandler);
      // };
    };
  }
};
</script>

<style lang="less" scoped>
.video {
  width: 100%;
  height: 100%;
}
</style>
