<template>
  <div class="live-player-right">
    <a-tabs :class="{ 'video-isShow': isShow }">
      <a-tab-pane key="1" v-if="!isShow" tab="弹幕消息">
        <div class="barrage-sending">
          <div class="barrage-sending-conetent" ref="chatListDom">
            <div class="barrage-sending-item" v-for="(item, index) in chatList" :key="index">
              <span>{{ item.userName }}</span
              >:
              <span>{{ item.msgContent }}</span>
            </div>
          </div>
          <div class="barrage-sending-button">
            <a-input-search
              v-model:value="chatVlaue"
              placeholder="快发送弹幕和直播间互动吧"
              enter-button="发送"
              size="large"
              @search="sendMessage"
            />
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" v-if="!isShow" tab="房间用户">
        <div class="room-customer">
          <div class="room-item-active room-customer-item" v-for="(item, index) in personnelList" :key="index">
            <div class="customer-item-content">
              <div class="room-customer-user">
                <img src="../assets/images/live-broadcast-watch/id.png" alt="" />
              </div>
              <span class="serial-number">{{ item.username }}</span>
              <div class="room-customer-ranks">
                <div class="room-customer-icon">
                  <img src="../assets/images/live-broadcast-watch/room-customer.png" alt="" />
                </div>
                <span>{{ item.departmentName }}</span>
              </div>
            </div>
            <a-button
              type="primary"
              ghost
              @click="kickOut(item)"
              v-if="
                item.username == userinfo.userName
                  ? false
                  : item.username == anchorName
                  ? false
                  : true || userinfo.isAdmin
              "
              >踢出</a-button
            >
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="3" tab="站内推送">
        <div class="push-station">
          <a-input-search v-model:value="userName" placeholder="搜索账号" @search="onUserNameSearch" />
          <a-tree
            checkable
            :tree-data="treeData"
            @check="onTreeClick"
            v-model:expandedKeys="expandedKeys"
            @select="Treeselect"
          />
          <a-button type="primary" @click="share">分享</a-button>
        </div>
      </a-tab-pane>
      <a-tab-pane key="4" tab="资源库">
        <div class="resource-pool">
          <a-input-search v-model:value="query" placeholder="主播呢称或直播间ID" @search="onQuerySearch" />
          <a-menu mode="inline" class="menu-box">
            <template v-for="item in ResourcePool" :key="item.key">
              <template v-if="!item.children">
                <a-menu-item :key="item.key">
                  <span>{{ item.resourceName }}</span>
                </a-menu-item>
              </template>
              <template v-else>
                <sub-menu :menu-info="item" :key="item.key" />
              </template>
            </template>
          </a-menu>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { ref, watch, inject, onBeforeUnmount, onMounted, reactive, toRefs } from 'vue';
import io from 'socket.io-client';
import { useRouter, useRoute } from 'vue-router';
import api from '@/services';
import { path } from '@/config';
import { getQueryVariable } from '@/utils/tool';
import SubMenu from '@/components/SubMenu.vue';
import { join, leave, options } from '@/utils/evenwheat.js';
import { message } from 'ant-design-vue';
import bus from '../bus';
export default {
  components: {
    'sub-menu': SubMenu
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  setup(props, content) {
    const state = reactive({
      liveSocket: null
    });
    // 锁 只执行一次
    const lock = ref(true);
    const route = useRoute();
    const router = useRouter();

    // 获取直播间信息
    const roomContent = inject('roomContent');
    const userinfo = JSON.parse(localStorage.getItem('SS_userinfo'));

    const chatListDom = ref(null);
    // 聊天展示
    const chatList = ref([]);
    // 聊天内容
    const chatVlaue = ref('');
    // 人员列表
    const personnelList = ref([]);
    const sendMessage = () => {
      if (!chatVlaue.value) {
        message.info('不可以发送空白弹幕');
        return false;
      }

      state.liveSocket.emit('sendMsg', {
        userName: userinfo.nickName,
        // 00 是消息
        // 01 上线提醒
        // 02 下线提醒
        // 03 暂无
        // 04 人数和人元列表
        msgType: '00',
        msgContent: chatVlaue.value
      });
      chatVlaue.value = '';
    };

    // socket心跳包
    const socketHeartbeat = () => {
      state.liveSocket.emit('heartbeat', { time: new Date(), text: 'heartbeat' });
      setTimeout(() => {
        socketHeartbeat();
      }, 5 * 1000);
    };

    // 初始化 socket
    const initSocket = () => {
      // http://172.16.16.90:9091  -- 线上
      // http://10.0.10.172:9091   -- 严彬本地
      console.log(path);
      state.liveSocket = io(path, {
        query: `method=live&userName=${userinfo.userName}&roomId=${roomContent.value.id}`,
        pingInterval: 2000,
        pingTimeout: 15000
      });

      state.liveSocket.on('connect', data => {
        console.log('直播间socket 连接成功=====', data);
        socketHeartbeat();
      });

      // 监听后端传过来的数据
      const implementFuntion = {
        '00': data => {
          console.log(data);
          chatList.value.unshift({
            userName: data.userName,
            msgContent: data.msgContent
          });
          // chatListDom.value.scrollTop = chatListDom.value.scrollHeight
        },
        '01': data => {
          chatList.value.unshift({
            userName: data.userName,
            msgContent: `${data.userName}上线了`
          });
        },
        '02': data => {
          chatList.value.unshift({
            userName: data.userName,
            msgContent: `${data.userName}下线了`
          });
        },
        '03': data => {
          content.emit('downcast', data);
          // message.info(`${data.msgContent}主播已下线`)
        },
        '04': data => {
          let userListValue = JSON.parse(data.msgContent).userList.filter(item => {
            if (item?.username) {
              return item;
            }
          });
          personnelList.value = userListValue;
          content.emit('peopleNumber', userListValue);
        },
        '05': data => {
          message.info('您已被踢出直播间');
          state.liveSocket.disconnect();
          router.push({
            path: '/home'
          });
        },
        '06': data => {
          content.emit('yesOnNo', data);
        },
        '07': async data => {
          const msg = JSON.parse(data.msgContent);
          if (!msg.attitude) {
            content.emit('isLianmai', '连麦');
            return false;
          }
          if (msg) {
            options.appid = msg?.appId;
            options.token = msg?.token;
            options.channel = route.query.id;
            try {
              await join();
              content.emit('isLianmai', '退出连麦');
              message.success('连麦成功');
            } catch (error) {
              console.error(error);
            }
          }
        },
        '08': data => {
          bus.emit('mapPoint', data);
        },
        '10': () => {
          message.success('正在切换资源');
        },
        '11': () => {
          message.success('切换资源完成');
          content.emit('switchLive');
        },
        '12': () => {
          leave();
          content.emit('isLianmai', '连麦');
        },
        '13': (data) => {
          content.emit('clickNumber', data);
        }
      };
      state.liveSocket.on('receiveMsg', data => {
        console.log('接收到了数据', data);
        implementFuntion[data.msgType](data);
      });

      // state.liveSocket.on('connect_error', () => {
      //   console.log('=====网络断掉');
      //   state.liveSocket.disconnect();
      // });
    };

    // 踢出
    const kickOut = item => {
      const userName = item.username;
      const roomId = roomContent.value.id;
      content.emit('kickOut', {
        userName,
        roomId
      });
    };

    // 站内推送
    const treeData = ref([]);
    const liveShareParams = ref({
      receiverIdList: [],
      // 主播ID
      anchorId: parseInt(getQueryVariable('roomUserId')),
      // 拉流地址
      pullLiveAddress:
        getQueryVariable('pullFlow') ||
        getQueryVariable('address')
          .split('?')
          .shift(),
      // 房间ID
      roomId: parseInt(getQueryVariable('id')),
      videoId: parseInt(getQueryVariable('resourceId'))
    });
    const userName = ref('');
    const PushStation = async () => {
      const { data } = await api.publisherFiltering(userName.value);
      treeData.value = data;
    };
    const onUserNameSearch = () => {
      PushStation();
    };

    const onTreeClick = (checkedKeys, { node }) => {
      console.log(checkedKeys, node.dataRef);
      liveShareParams.value.receiverIdList = checkedKeys;
    };
    const expandedKeys = ref([]);
    const Treeselect = (selectedKeys, e) => {
      // 展开
      const seleedKey = e.node.eventKey;
      let length = expandedKeys.value.length > 0 ? expandedKeys.value.length : 1;
      for (let index = 0; index < length; index++) {
        // 表示没有key 添加进入
        if (expandedKeys.value.indexOf(seleedKey) == -1) {
          console.log('展开');
          expandedKeys.value = expandedKeys.value.concat([seleedKey]);
          return false;
        } else {
          console.log('不展开');
          expandedKeys.value.splice(expandedKeys.value.indexOf(seleedKey), 1);
          return false;
        }
      }
      console.log(expandedKeys.value);
    };

    // 分享
    const share = async () => {
      const { code, data } = await api.liveShare(liveShareParams.value);
      if (code) message.success('推送成功');
    };

    // 资源库
    const ResourcePool = ref([]);
    const query = ref('');
    const getResourcePool = async () => {
      const { data } = await api.getResourceSelection(query.value);
      ResourcePool.value = data;
    };
    const onQuerySearch = () => {
      getResourcePool();
    };

    const anchorName = ref('');

    // 判断是否是进入直播间
    watch(roomContent?.value, () => {
      // 只执行一次
      if (lock.value) {
        initSocket();
        lock.value = false;
        anchorName.value = roomContent.value.username;
      }
    });

    onMounted(() => {
      PushStation();
      getResourcePool();
    });

    onBeforeUnmount(() => {
      if (state.liveSocket) {
        state.liveSocket.emit('exit', { method: 'live', roomId: roomContent.value.id });
        state.liveSocket.disconnect();
      }
    });
    return {
      ...toRefs(state),
      roomContent,
      // 聊天室
      chatListDom,
      chatList,
      chatVlaue,
      sendMessage,
      // 人员列表
      personnelList,
      // 踢出
      kickOut,
      // 站内推送
      userName,
      onUserNameSearch,
      treeData,
      onTreeClick,
      share,
      Treeselect,
      expandedKeys,
      // 资源库
      ResourcePool,
      onQuerySearch,
      query,
      // 当前用户信息
      userinfo,
      anchorName
    };
  }
};
</script>

<style lang="less" scoped>
@import '../assets/less/public.less';
.live-player-right {
  width: 24.215%;
  border-radius: 10px;
  display: flex;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  flex-direction: column;
  .ant-tabs-line {
    height: 100%;
    border-radius: 10px;
    ::v-deep(.ant-tabs-bar) {
      border: none;
      width: 100%;
      margin-bottom: 0;
      .ant-tabs-nav-container {
        padding: 0
      }
      .ant-tabs-nav {
        border-radius: 10px 10px 0 0;
        background-color: #eeeeee;
        padding: 24px 20px 15px 27px;
        width: 100%;
        & > div {
          display: flex;
          .ant-tabs-tab {
            padding: 0;
            text-align: center;
            &:not(:last-of-type) {
              margin: 0 23px 0 0;
            }
          }
        }
      }
    }
    ::v-deep(.ant-tabs-top-content) {
      height: calc(100% - 60px);
      .barrage-sending,
      .room-customer,
      .push-station,
      .resource-pool {
        padding-top: 15px;
        height: 100%;
        background-color: #fff;
      }

      .barrage-sending {
        position: relative;
        .barrage-sending-conetent {
          padding: 0 20px 0 20px;
          max-height: 490px;
          overflow: auto;
          .barrage-sending-item {
            margin-bottom: 15px;
            & > span:first-of-type {
              color: #1e77cf;
            }
          }
          .barrage-sending-item:last-child {
            margin-bottom: 40px;
          }
        }
        .barrage-sending-button {
          border-top: 2px solid #e5e5e5;
          padding: 15px 17px 16px 18px;
          position: absolute;
          bottom: 0;
          width: 100%;
          input {
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
            border-radius: 10px 0 0 10px;
            border-color: #007fff;
          }
          button {
            border-radius: 0 10px 10px 0;
            background-color: #007fff;
            width: 62px;
            padding: 0;
          }
        }
      }
      .room-customer {
        .room-customer-item {
          display: flex;
          align-items: center;
          padding: 10px 20px;
          justify-content: space-between;
          .customer-item-content {
            display: flex;
            align-items: center;
            .serial-number {
              color: #007fff;
              margin: 0 20px 0 10px;
              padding-top: 1px;
            }
            .room-customer-user {
              width: 24px;
              height: 24px;
              border-radius: 50%;
            }
            .room-customer-ranks {
              display: flex;
              padding-top: 1px;
              .room-customer-icon {
                width: 8px;
                height: 13px;
                margin-right: 4px;
              }
            }
          }

          button {
            border-radius: 20px;
            width: 48px;
            height: 24px;
            padding: 0;
          }
        }
        .room-item-active {
          background-color: #f6f6f6;
        }
      }
      .push-station {
        position: relative;
        padding: 15px 20px 20px;
        .ant-input {
          height: 30px;
          width: 100%;
          border-radius: 10px;
        }
        .ant-tree {
          margin-top: 11px;
          overflow-y: auto;
          max-height: 450px;
          li {
            padding: 0;
            margin: 4px 0;
          }
          .scroll();
          &::-webkit-scrollbar {
            width: 5px;
          }
        }
        .ant-btn {
          width: calc(100% - 30px);
          background-color: #007fff;
          border-radius: 5px;
          height: 36px;
          position: absolute;
          bottom: 20px;
        }
      }
      .ant-input-search {
        padding: 0 10px;
        border-radius: 30px;
      }
      .resource-pool {
        padding: 15px 20px 40px 19px;
        .ant-input {
          height: 30px;
          width: 100%;
          background-position: 262px center;
        }
        .menu-box {
          margin-top: 11px;
          overflow-y: auto;
          overflow-x: hidden;
          border: none;
          max-height: 480px;
          .scroll();
          &::-webkit-scrollbar {
            width: 5px;
          }
          .ant-menu-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            & > span {
              width: 230px;
              .text-overflow(200px);
            }
            button {
              width: 99px;
              height: 20px;
              border-radius: 20px;
              padding: 0;
              font-size: 12px;
            }
          }
          .ant-menu-item-selected {
            background-color: #f6f6f6;
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
  .video-isShow {
    ::v-deep(.ant-tabs-bar) {
      .ant-tabs-nav {
        padding: 24px 57px 15px;
        & > div {
          .ant-tabs-tab {
            width: 50%;
          }
        }
      }
    }
    .ant-tree {
      max-height: 520px !important;
    }
  }
}
</style>
